import { AnyNonNullable, assertIsDefined, Nullable } from '@smd/utilities';
import { getMeridianDataLayerOrNull } from '@smd/datalayer-typings';
import * as Core from '../../../core';
import { Api } from '../Api';
import type { PulseGetAdvertisingIdentifiersFunction } from '@smd/tracking';

export class Ppid extends Core.Service.Generic.State.Active<Ppid.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		const ppid = (await Ppid.Delta.get()) ?? Ppid.DataLayer.get();

		if (!ppid) {
			Core.log.warn('CONFIGURATION', 'GPT', 'Missing PPID');
			return;
		}

		await Api.execute(
			function () {
				Core.log('CONFIGURATION', 'GPT', 'Setting PPID', ppid);
				this.pubads().setPublisherProvidedId(ppid);
			},
			this.abortSignal,
			abortSignal,
		);
	}

	protected override executeDestroy() {}
}

export namespace Ppid {
	export type Options = Readonly<AnyNonNullable>;

	export type Delta = Awaited<ReturnType<PulseGetAdvertisingIdentifiersFunction>>['delta'];

	export namespace Delta {
		export async function get() {
			let delta: Nullable<Delta> = null;

			try {
				const item = localStorage.getItem('_pulse.internal.identity.ppid');
				assertIsDefined(item);

				const parsed = JSON.parse(item) as { delta?: Delta };
				delta = parsed.delta;
			} catch {
				// Do nothing
			}

			if (!delta) {
				try {
					delta = (await window.pulse('getAdvertisingIdentifiers')).delta;
				} catch {
					// Do nothing
				}
			}

			if (!delta) return null;

			if (delta.ppId1) {
				Core.log('CONFIGURATION', 'GPT', 'Retrieving PPID from delta ppId1', delta.ppId1);
				return delta.ppId1;
			}

			if (delta.ppId2) {
				Core.log('CONFIGURATION', 'GPT', 'Retrieving PPID from delta ppId2', delta.ppId2);
				return delta.ppId2;
			}

			return null;
		}
	}

	export namespace DataLayer {
		export function get() {
			const ppid = getMeridianDataLayerOrNull()?.u?.ppid;
			if (!ppid) return null;

			Core.log('CONFIGURATION', 'GPT', 'Retrieving PPID from dataLayer', ppid);

			return ppid;
		}
	}
}
